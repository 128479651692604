.input-range__slider {
    appearance: none;
    background: #21BB8E;
    border: 4px solid #21BB8E;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 24px;
    width: 24px;
    margin-left: -0.5rem;
    margin-top: -0.85rem;
    outline: none;
    position: absolute;
    top: 50%;
    z-index: 0;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  }
  .input-range__slider:active {
    transform: scale(1.1);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
    cursor: not-allowed;
  }

  .input-range__slider-container {
    /* transition: left 0.3s ease-out; */
    transition: none;
  }

  .input-range__label {
    color: #aaaaaa;
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    color: #ffffff;
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    color: #263238;
    font-weight: 500;
    top: -2.25rem;
  }

  .input-range__label-container {
    position: relative;
    z-index: -1;
  }
  .input-range__label--max .input-range__label-container {
    right: -36px;
    top: -18px;
  }

  .input-range__label--max .input-range__label-container::after {
content: "X";
  }

  .input-range__label--min .input-range__label-container {
    left: -20px;
    top: -18px;
  }

  .input-range__label--min .input-range__label-container::after {
    content: "X";
      }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.375rem;
    position: relative;
    /* transition: left 0.3s ease-out, width 0.3s ease-out; */
    transition: none;
  }
  .input-range--disabled .input-range__track {
    background: #23335E;
    cursor: not-allowed;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    background-color: #1D2533F2;
    border-radius: 10px;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background:#21BB8E;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: calc(100%-40px);
    margin-left: 20px;
    margin-right: 36px;
  }

  .input-range__track.input-range__track--background
    span.input-range__slider-container:nth-child(2)
    span.input-range__label.input-range__label--value
    span.input-range__label-container {
    display: none;
  }

  .input-range__track.input-range__track--background
    span.input-range__slider-container:nth-child(3)
    span.input-range__label.input-range__label--value
    span.input-range__label-container {
    z-index: 1;
    background-color: #ffffff;
  }

  /*# sourceMappingURL=index.css.map */
