@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-ellipsis {
    @apply overflow-ellipsis whitespace-nowrap overflow-hidden;
  }

  .header {
    @apply text-sm font-bold text-primary
  }
}
@layer components {
  ul, ol {
    list-style: revert;
  }
}

body {
  margin: 0;
  font-family: 'Noto Sans Thai', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 16px;
  color: #ffffff;
  overflow-y: auto !important;
  height: auto !important;
  width: auto !important;
  min-width: auto !important;
  min-height: auto !important;
  background-color: #1F1F1F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:checked ~ .checkmark {
  background-color: #007aff;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.markdown > * {
  all: revert;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.hedge-mode-button {
  border: double 1px transparent;
  border-radius: 4px;
  background-image: linear-gradient(#0F0F10,#0F0F10), linear-gradient(97.79deg, #60C97E -6.09%, #EB5D57 108.8%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #BBAF7A;
}

.custom-bin-outline:hover path {
  stroke: #e55454;
}